import React, { useState } from 'react';
import { styled } from '../../../stitches.config';
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import Box from '../Box';
import useMediaQuery from "../../../helpers/useMediaQuery";

// icons 
import ArrowLeft from '../../../assets/img/mamadu/carrefour/ArrowLeft.png'
import ArrowRight from '../../../assets/img/mamadu/carrefour/ArrowRight.png'

// logo i background do wgrania 
import backgroundMobile from '../../../assets/img/natemat/minisite/MS-background-mobile.png';
import logo from '../../../assets/img/natemat/minisite/MS-logo-desktop.png';
import logoMobile from '../../../assets/img/natemat/minisite/MS-logo-mobile.png';

// strzałki do slidera

function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <CustomArrow
            className={className}
            style={{ ...style, display: "block" }}
            onClick={onClick}
        >
            <img src={ArrowRight} />
        </CustomArrow>
    );
}

function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <CustomArrow
            className={className}
            style={{ ...style, display: "block" }}
            onClick={onClick}
        >
            <img src={ArrowLeft} />
        </CustomArrow>
    );
}

// losowe ustawienie slidów

const shuffleCards = (number) => (cards) => {
    const array = Object.keys(cards).map(k => cards[k]);
    let currentIndex = array.length, randomIndex;

    while (currentIndex != 0) {
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;

        [array[currentIndex], array[randomIndex]] = [
            array[randomIndex], array[currentIndex]];
    }
    const obj = array.reduce((acc, curr, i) => (acc[`b${i + number}`] = curr, acc), {})

    return obj
}

const shuffleCardsAll = shuffleCards(1)

export default props => {
    const [cardsDesktop] = useState(() => shuffleCardsAll([props.settings.b1, props.settings.b2, props.settings.b3, props.settings.b4, props.settings.b5]));
    const [cardsMobile] = useState(() => shuffleCardsAll([props.settings.b1, props.settings.b2, props.settings.b3]))


    const isDesktop = useMediaQuery('bp4');

    const settings = {
        infinite: true,
        dots: true,
        arrows: true,
        speed: 500,
        // uwzględniać 3 lub 4 slidesToShow razem z poniższym komentarzem
        slidesToShow: 4,
        slidesToScroll: 1,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        responsive: [
            {
                // uwzględniać ten breakpoint gdy mają być widoczne 4 slidy na dekstopie
                breakpoint: 1370,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    arrows: true,
                    dots: true,
                }
            },
            {
                breakpoint: 996,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    centerMode: true,
                    arrows: false,
                    infinite: true,
                    dots: true,
                    centerPadding: "45px",
                }
            },
            {
                breakpoint: 720,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerMode: true,
                    arrows: false,
                    infinite: true,
                    dots: true,
                    centerPadding: "70px",
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerMode: true,
                    arrows: false,
                    infinite: true,
                    dots: true,
                    centerPadding: "50px",
                }
            },
            {
                breakpoint: 420,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerMode: true,
                    arrows: false,
                    infinite: true,
                    dots: true,
                    centerPadding: "30px",
                }
            },
            {
                breakpoint: 375,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerMode: true,
                    arrows: false,
                    infinite: true,
                    dots: true,
                    centerPadding: "20px",
                }
            }
        ]
    };
    return (
        <Section>
            <Wrapper>
                <Logo>
                    <img className="logo desktop" src={logo} />
                    <img className="logo-mobile mobile" src={logoMobile} />
                </Logo>

                <CarouselWrapper>
                    <Slider {...settings}>
                        <Box {...props}
                            id="b1"
                            type="AMiniSite"
                            settings={props.settings.b1}
                        />
                        <Box {...props}
                            id="b2"
                            type="AMiniSite"
                            settings={props.settings.b2}
                        />
                        <Box {...props}
                            id="b3"
                            type="AMiniSite"
                            settings={props.settings.b3}
                        />
                        <Box {...props}
                            id="b4"
                            type="AMiniSite"
                            settings={props.settings.b4}
                        />
                    </Slider>
                </CarouselWrapper>
                <CarouselWrapper>
                    <Slider {...settings}>
                        <Box {...props}
                            id="b5"
                            type="AMiniSite"
                            settings={props.settings.b5}
                        />
                        <Box {...props}
                            id="b6"
                            type="AMiniSite"
                            settings={props.settings.b6}
                        />
                        <Box {...props}
                            id="b7"
                            type="AMiniSite"
                            settings={props.settings.b7}
                        />
                        <Box {...props}
                            id="b8"
                            type="AMiniSite"
                            settings={props.settings.b8}
                        />
                    </Slider>
                </CarouselWrapper>
            </Wrapper>
        </Section>
    );
}

// slider

const CustomArrow = styled("div", {
    "img": {
        width: "43px",
        height: "43px",
        margin: "auto",
        position: "absolute",
        top: "-10px",
        left: "0"
    }
});

const CarouselWrapper = styled("div", {
    marginBottom: "55px",
    overflow: "visible",
    ".slick-list": {
        marginBottom: "50px",
        "@bp4": {
            marginBottom: "0px",
            padding: "10px 0px"
        }
    },
    ".slick-slide": {
        "& > div": {
            display: "grid",
            placeItems: "center"
        }
    },
    ".slick-arrow": {
        zIndex: 10
    },
    ".slick-prev": {
        left: "380px",
        top: "382px",
        "@bp5": {
            left: "530px",
        }
    },
    ".slick-next": {
        right: "410px",
        top: "382px",
        "@bp5": {
            right: "560px",
        }
    },
    ".slick-dots": {
        bottom: "-35px",
        "li": {
            margin: "0",
            width: "18px",
            height: "9px",
            "&.slick-active": {
                button: {
                    "&:before": {
                        /* main kolor minisite */
                        color: "#f82626",
                        opacity: 1,
                        "@bp4": {
                            borderRadius: "10px"
                        }
                    }
                }
            },
            button: {
                width: "9px",
                height: "9px",
                padding: "0",
                "&:before": {
                    width: "9px",
                    height: "9px",
                    fontSize: "9px",
                    lineHeight: "9px",
                    color: "white",
                    opacity: 1,
                    "@bp4": {
                        borderWidth: "0px",
                        borderRadius: "10px"
                    }
                }
            }
        }
    }
});

const Section = styled("div", {
    overflow: "hidden",
    width: "100%",
    paddingBottom: "1px",
    backgroundColor: "#bbdff4",
    backgroundImage: `url(${backgroundMobile})`,
    backgroundPosition: "top center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    position: "relative",
    "@bp4": {
        backgroundImage: `none`,
        height: "1200px"
    },
    ".desktop": {
        display: "none",
        "@bp4": {
            display: "block"
        }
    },
    ".mobile": {
        "@bp4": {
            display: "none"
        }
    },

});

const Wrapper = styled("div", {
    width: "100%",
    margin: "0 auto",
    paddingTop: "395px",
    "@bp4": {
        paddingTop: "360px",
        flexDirection: "row",
        maxWidth: "972px",
        justifyContent: "space-between",
        alignItems: "flex-start"
    },
    /* ustawiać ten breakpoint gdy są 4 slidy na dekstopie 
    @media only screen and (min-width: 1370px) {
        max-width: 1266px;
    } */
    "@bp5": {
        maxWidth: "1280px"
    },
    "h1": {
        fontSize: "30px",
        lineHeight: "38px",
        color: "#D7141A",
        textAlign: "center",
        paddingTop: "10px",
        margin: "0",
        fontWeight: 800,
        fontFamily: "$grotesk",
        position: "relative",
        "@bp4": {
            marginBottom: "0px",
            fontSize: "34px",
            lineHeight: "42px"
        }
    },
    ".h1-bold": {
        color: "#242424",
        marginBottom: "0px",
        paddingTop: "10px",
        "@bp4": {
            paddingTop: "0",
            marginBottom: "185px"
        }
    }
});

const Logo = styled("div", {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    position: "absolute",
    top: "0",
    left: "0",
    ".logo": {
        marginTop: "-105px",
        maxWidth: "2000px",
    },
    ".logo-mobile": {
        marginTop: "-95px",
        maxWidth: "540px",
    },
});

const BoxWrapper = styled("div", {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "column",
    gap: "24px",
    "@bp4": {
        flexDirection: "row"
    },
});